<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="false" />
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 sm12 md12>
        <v-card id="app_height" outlined>
          <v-layout pa-4 wrap justify-center>
            <v-flex text-center>
              <span style="font-size:16px;font-weight:600">Log Details</span>
            </v-flex>
          </v-layout>
          <v-layout px-5 wrap justify-center align="center">
            <v-flex>
              <v-card>
                <table class="logtable" style width="100%">
                  <tr>
                    <th>Browser</th>
                    <td>{{log.browser}}</td>
                  </tr>
                  <tr>
                    <th>OS</th>
                    <td>{{log.os}}</td>
                  </tr>
                  <tr>
                    <th>Platform</th>
                    <td>{{log.platform}}</td>
                  </tr>
                  <tr>
                    <th>Source</th>
                    <td>{{log.source}}</td>
                  </tr>
                  <tr>
                    <th>URL</th>
                    <td>{{log.url}}</td>
                  </tr>
                  <tr>
                    <th>Body</th>
                    <td>{{log.body}}</td>
                  </tr>
                  <tr>
                    <th>Query</th>
                    <td>{{log.query}}</td>
                  </tr>
                  <tr>
                    <th>Params</th>
                    <td>{{log.params}}</td>
                  </tr>
                  <tr>
                    <th>IP</th>
                    <td>{{log.ip}}</td>
                  </tr>
                  <tr>
                    <th>Original URL</th>
                    <td>{{log.originalUrl}}</td>
                  </tr>
                  <tr>
                    <th>IPv4</th>
                    <td>{{log.ipv4}}</td>
                  </tr>
                  <tr>
                    <th>Log Time</th>
                    <td>{{log.logtime}}</td>
                  </tr>
                </table>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import VueElementLoading from "vue-element-loading";
export default {
  props: ["id"],
  components: {
    VueElementLoading
  },
  data() {
    return {
      appLoading: false,
      msg: null,
      showSnackBar: false,
      timeout: 3000,
      log: {}
    };
  },
  beforeMount() {
    this.getLog();
  },
  methods: {
    getLog() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/log/get/",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        params: {
          id: this.id
        }
      })
        .then(response => {
          this.appLoading = false;
          if (response.data.status) {
            this.log = response.data.data;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    }
  }
};
</script>
<style>
@media only screen and (min-width: 800px) {
  #app_height {
    min-height: 80vh;
    min-width: 80vw;
  }
}

.logtable,
.logtable th,
.logtable td {
  border: 1px solid black;
  border-collapse: collapse;
}
.logtable .logtable th,
.logtable td {
  padding: 5px;
  padding-left: 20px;
  text-align: left;
}
.logtable th {
  text-align: left;
  padding: 5px;
  padding-left: 50px;
  max-width: 80px;
}
</style>